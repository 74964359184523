import { forwardRef, useEffect } from "react";
import { useIQContext } from "../../../IQContext";
import IQMessage from "./IQMessage";
import IQLoading from "./IQLoading";
import ForagerButton from "../../../Generic/ForagerButton";

function IQChatContent({ ScrollToBottom }, messagesEndRef) {
  const { pastMessages, isThinking, ClearPastMessages } = useIQContext();

  const hasMessages = pastMessages.length > 0;

  useEffect(() => {
    if (messagesEndRef?.current) {
      ScrollToBottom();
    }
  }, [pastMessages, isThinking, ScrollToBottom, messagesEndRef]);

  return (
    <>
      <div className="IQBody">
        <div className="IQChatContent">
          {pastMessages.length > 0 &&
            pastMessages
              .filter(
                (message) =>
                  (message.role === "user" || message.role === "assistant") &&
                  message.content !== null &&
                  message.content !== ""
              )
              .map((message, index) => {
                return (
                  <IQMessage
                    message={message.content}
                    role={message.role}
                    key={index}
                  />
                );
              })}
          {isThinking && <IQLoading />}
          <div ref={messagesEndRef}></div>
        </div>
      </div>
      {hasMessages && (
        <ForagerButton
          className="clearChatButton"
          dataTip="Clear Chat"
          onClick={ClearPastMessages}
          lessVerticalPadding={true}
          circular={true}
          smallText={true}
        >
          X
        </ForagerButton>
      )}
    </>
  );
}

export default forwardRef(IQChatContent);
