import { useState, useEffect } from "react";
import useGetDocs from "./useGetDocuments";
import useGetKnowledgeBases from "./useGetKnowledgeBases";

function useGetKnowledgeBasesWithDocs(searchText = "") {
  const [knowledgeBasesWithDocs, SetKnowledgeBasesWithDocs] = useState(null);
  const documents = useGetDocs();
  const { knowledgeBases } = useGetKnowledgeBases();

  function PutDocsInKnowledgeBases() {
    if (knowledgeBases.length === 0 || documents.length === 0) {
      return;
    }

    let knowledgeBasesWithDocs = knowledgeBases.map((kb) => {
      let docs = documents.filter(
        (d) =>
          d.knowledgeBases?.some((id) => id === kb.id) &&
          d.fileName.toLowerCase().includes(searchText.toLowerCase())
      );
      if (docs.length === 0) {
        return null;
      }
      return { ...kb, docs: docs };
    });
    knowledgeBasesWithDocs = knowledgeBasesWithDocs.filter((kb) => kb !== null);
    SetKnowledgeBasesWithDocs([...knowledgeBasesWithDocs]);
  }

  useEffect(() => {
    if (knowledgeBases === null || documents === null) {
      return;
    }

    PutDocsInKnowledgeBases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, knowledgeBases, searchText]);

  return knowledgeBasesWithDocs;
}

export default useGetKnowledgeBasesWithDocs;
