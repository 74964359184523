import React, { useState } from "react";
import Modal from "../../../../Generic/Modal";
import UserManagement from "./SidebarModals/Admin/UserManagement/UserManagement";
import CustomerManagement from "./SidebarModals/Admin/CustomerManagement/CustomerManagement";

import ImpersonateModal from "../Impersonate/ImpersonateModal";
import ChangePasswordModal from "./SidebarModals/ChangePassword";
import { useSiteContext } from "../../../../Context";
import { useIQContext } from "../../../../IQContext";
import ForagerButton from "../../../../Generic/ForagerButton";

function SidebarButtons(props) {
  const [userManagement, SetUserManagement] = useState(false);
  const [customerManagement, SetCustomerManagement] = useState(false);
  const [editsPending, SetEditsPending] = useState(false);
  const [impersonateModalOpen, SetImpersonateModalOpen] = useState(false);
  const [changePassword, SetChangePassword] = useState(false);
  const { loggedInUser, fieldCustomizations, PermissionCheck } =
    useSiteContext();
  const { ShowExtendedSideBar } = useIQContext();

  function PresentModal(modalName, value, override = false) {
    let canContinue = true;
    if (!override && !value && editsPending) {
      canContinue = window.confirm(
        "You have unsaved changes that will be lost. Are you sure you want to close this window?"
      );
    }
    if (!canContinue) {
      return;
    }
    SetEditsPending(false);
    switch (modalName) {
      case "Impersonate":
        SetImpersonateModalOpen(value);
        break;
      case "DocumentManagement":
        ShowExtendedSideBar("DocumentManagement", null, true);
        break;
      case "UserManagement":
        SetUserManagement(value);
        break;
      case "CustomerManagement":
        SetCustomerManagement(value);
        break;
      case "ChangePassword":
        SetChangePassword(value);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className="sidebarButtonsContainer">
        <div className="sidebarSection">
          <div
            className="sidebarSectionTitle"
            onClick={() => props.ToggleIQOpen(true)}
          >
            IQ
          </div>
          {PermissionCheck(17, 3) ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("DocumentManagement", true)}
            >
              Document Management
            </ForagerButton>
          ) : null}
        </div>
        <div className="sidebarSection">
          <div className="sidebarSectionTitle">Options</div>
          {loggedInUser.isHammr || props.impersonating ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() =>
                props.impersonating
                  ? props.ImpersonateUser(null)
                  : PresentModal("Impersonate", true)
              }
            >
              {props.impersonating
                ? "End Impersonate"
                : "Impersonate " + fieldCustomizations.general.user.name}
            </ForagerButton>
          ) : null}
          {loggedInUser.isHammr ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("CustomerManagement", true)}
            >
              Customer Management
            </ForagerButton>
          ) : null}
          {PermissionCheck(2, 1) ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("UserManagement", true)}
            >
              {fieldCustomizations.general.user.name + " Management"}
            </ForagerButton>
          ) : null}

          <ForagerButton
            fullWidth={true}
            smallText={true}
            lessRadius={true}
            noVerticalPadding={true}
            onClick={() => PresentModal("ChangePassword", true)}
          >
            Change Password
          </ForagerButton>
        </div>
      </div>
      {impersonateModalOpen ? (
        <Modal
          CloseModal={() => PresentModal("Impersonate", false)}
          modificationClass="noPaddingAlternate"
        >
          <ImpersonateModal
            ImpersonateUser={props.ImpersonateUser}
            CloseModal={() => PresentModal("Impersonate", false)}
          />
        </Modal>
      ) : null}
      {userManagement ? (
        <Modal
          CloseModal={() => PresentModal("UserManagement", false)}
          modificationClass="noPaddingAlternate"
        >
          <UserManagement
            CloseModal={(override = false) =>
              PresentModal("UserManagement", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}
      {customerManagement ? (
        <Modal
          CloseModal={() => PresentModal("CustomerManagement", false)}
          modificationClass="noPaddingAlternate"
        >
          <CustomerManagement
            CloseModal={(override = false) =>
              PresentModal("CustomerManagement", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}

      {changePassword ? (
        <Modal
          CloseModal={() => PresentModal("ChangePassword", false)}
          modificationClass="noPaddingAlternate"
        >
          <ChangePasswordModal
            CloseModal={() => PresentModal("ChangePassword", false)}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default SidebarButtons;
