import { Icon } from "@iconify/react";
import { useIQContext } from "../../../IQContext";
import SelectedKnowledgeBases from "./SelectedKnowledgeBases";

export default function IQChatBar() {
  const {
    selectedKnowledgeBases,
    isThinking,
    question,
    SetQuestion,
    AskQuestion,
  } = useIQContext();
  const isDisabled =
    question === "" || selectedKnowledgeBases.length === 0 || isThinking;

  function AskQuestionStart(
    newQuestion,
    isSummary = false,
    knowledgeBaseIds = null
  ) {
    AskQuestion(newQuestion, isSummary, knowledgeBaseIds);
  }

  function HandleTyping(event) {
    SetQuestion(event.target.value);
  }

  function HandleClick(e) {
    AskQuestionStart(question);
  }

  function HandleEnterKey(event) {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      selectedKnowledgeBases.length > 0 &&
      question !== "" &&
      !isThinking
    ) {
      AskQuestionStart(question);
      event.preventDefault();
    }
  }

  return (
    <div className="IQChatBarContainer">
      <div className="inputContainer">
        <SelectedKnowledgeBases
          selectedKnowledgeBases={selectedKnowledgeBases}
        />

        <input
          name="question"
          type="=text"
          placeholder="Enter your question here..."
          className="chatBar"
          id="questionBox"
          value={question}
          onChange={HandleTyping}
          onKeyUp={HandleEnterKey}
        ></input>
        <div className={"askQuestionWrapper" + (isDisabled ? " disabled" : "")}>
          <Icon id="sendQuestion" icon={"mdi:chat"} onClick={HandleClick} />
        </div>
      </div>
    </div>
  );
}
