import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../../../../../../../Context";

function Permission(props) {
  const [enabled, SetEnabled] = useState(false);
  const [checked, SetChecked] = useState(false);
  const { mainUser } = useSiteContext();

  function ProcessCurrentState() {
    let inCurrentPermissions = props.currentPermissions?.some(
      (permission) => permission.id === props.permission.id
    );
    let inAddPermissions = props.addPermissions.includes(props.permission.id);
    let inRemovePermissions = props.removePermissions.includes(
      props.permission.id
    );

    if (inRemovePermissions) {
      SetChecked(false);
      return;
    }
    if (inAddPermissions) {
      SetChecked(true);
      return;
    }
    SetChecked(inCurrentPermissions);
  }

  function PermissionChange(event) {
    SetChecked(event.target.checked);
    props.PermissionChange(
      props.permission.id,
      props.permission.name,
      event.target.checked
    );
  }

  useEffect(() => {
    ProcessCurrentState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPermissions, props.addPermissions, props.removePermissions]);

  useEffect(() => {
    SetEnabled(
      mainUser.permissions.some(
        (permission) => permission.id === props.permission.id
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainUser]);

  return (
    <div className="permissionContainer">
      <input
        disabled={!enabled}
        className="permissionCheckbox"
        type="checkbox"
        checked={checked}
        onChange={PermissionChange}
      />
      <div
        className={
          enabled ? "permissionTitle" : "permissionTitle permissionDisabled"
        }
      >
        {props.permission.name}
      </div>
    </div>
  );
}

export default Permission;
