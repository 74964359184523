import React from "react";
import { Icon } from "@iconify/react";
import GPTLogoSVG from "../../../Generic/GPTLogoSVG";
import { useIQContext } from "../../../IQContext";
import { useSiteContext } from "../../../Context";

function IQSideBar(props) {
  const {
    ShowExtendedSideBar,
    expandedSideBar,
    ToggleChatModel,
    chatModelOption,
  } = useIQContext();

  const { PermissionCheck } = useSiteContext();

  return (
    <div className="iqSideBar">
      {/* <div
        className={
          "iqSideBarButton" + (expandedSideBar === "Search" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("Search")}
      >
        <Icon icon="mdi:magnify" />
      </div>
      <div
        className={
          "iqSideBarButton" +
          (expandedSideBar === "OpenFile" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("OpenFile")}
      >
        <Icon icon="mdi:folder-open" />
      </div> */}
      {/* <div
        className={
          "iqSideBarButton" + (expandedSideBar === "" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("")}
      >
        <Icon icon="mdi:chat" />
      </div> */}
      <div
        className={
          "iqSideBarButton" +
          (expandedSideBar === "KnowledgeBase" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("KnowledgeBase")}
        data-tip="Select Knowledge Bases"
      >
        <Icon icon="fluent:book-search-24-regular" />
      </div>
      <div
        className={
          "iqSideBarButton" +
          (expandedSideBar === "DocumentManagement" ? " selected" : "") +
          (PermissionCheck(17, 3) ? "" : " disabled")
        }
        onClick={
          PermissionCheck(17, 3)
            ? () => ShowExtendedSideBar("DocumentManagement")
            : null
        }
        data-tip="Document Management"
      >
        <Icon icon="mdi:bookshelf" />
      </div>
      <div
        className="iqSideBarButton"
        onClick={ToggleChatModel}
        data-tip={"Switch To GPT " + (chatModelOption === "3.5" ? "4o" : "3.5")}
      >
        <div className="iqSideBarLogoWrapper">
          <GPTLogoSVG model={chatModelOption} />
        </div>
      </div>
      {/* <div
        className={
          "iqSideBarButton" + (expandedSideBar === "Share" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("Share")}
      >
        <Icon icon="mdi:share-variant-outline" />
      </div> */}
      {/* <div
        className={
          "iqSideBarButton" +
          (expandedSideBar === "Download" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("Download")}
      >
        <Icon icon="mdi:tray-arrow-down" />
      </div> */}
      <div
        className="iqSideBarButton"
        onClick={() => ShowExtendedSideBar("Help")}
        data-tip="Help"
      >
        <Icon icon="mdi:help-circle-outline" />
      </div>
      {/* <div
        className={
          "iqSideBarButton" +
          (expandedSideBar === "Settings" ? " selected" : "")
        }
        onClick={() => ShowExtendedSideBar("Settings")}
      >
        <Icon icon="mdi:cog" />
      </div> */}
    </div>
  );
}

export default IQSideBar;
