import IQSideBar from "./IQComponents/IQSideBar";
import PDFViewerModal from "../../Generic/PDFViewerModal";
import IQBody from "./IQComponents/IQBody";

function IQ() {
  return (
    <>
      <IQSideBar />
      <IQBody />
      <PDFViewerModal noPadding={true} />
    </>
  );
}
export default IQ;
