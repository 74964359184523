import ForagerTable from "../../../Generic/ForagerTable";
import ForagerTableColumn from "../../../Classes/ForagerTableColumn";
import { Icon } from "@iconify/react";
import { useIQContext } from "../../../IQContext";
import useGetKnowledgeBasesWithDocs from "../../../../Hooks/useGetKnowledgeBasesWithDocs";

export default function KnowledgeBaseTable({ triggerAllExpanded, searchText }) {
  const { ShowExtendedSideBar, OpenInPDFViewer, ChangeFilePreview } =
    useIQContext();
  const knowledgeBases = useGetKnowledgeBasesWithDocs(searchText);

  function HandleDocClick(doc) {
    ChangeFilePreview(doc.fileUrl);
  }

  const knowledgeBaseColumns = [
    new ForagerTableColumn({
      header: "Name",
      property: "name",
      sortable: true,
      stretch: true,
    }),
    new ForagerTableColumn({
      header: "",
      ValueFunction: (item) => {
        return (
          <div
            className="KnowledgeBaseListItemPart action"
            onClick={() => {
              ShowExtendedSideBar("KnowledgeBaseManagementEdit", item);
            }}
          >
            <Icon icon="akar-icons:pencil" />
            <span className="tooltip">Edit Knowledge Base</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      header: "",
      ValueFunction: (item) => {
        return (
          <div
            className="KnowledgeBaseListItemPart action"
            onClick={() => {
              ShowExtendedSideBar("KnowledgeBaseManagementDelete", item);
            }}
          >
            <Icon icon="akar-icons:trash-can" />
            <span className="tooltip">Delete Knowledge Base</span>
          </div>
        );
      },
      width: 3,
    }),
  ];

  const documentColumns = [
    new ForagerTableColumn({
      header: "File Name",
      property: "fileName",
      sortable: true,
      stretch: true,
      isDefaultSort: true,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              OpenInPDFViewer(item.uri);
            }}
          >
            <Icon icon="material-symbols:open-in-browser" />
            <span className="tooltip">Open in the Browser</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              window.open(item.uri, "_blank");
            }}
          >
            <a
              href={item.uri}
              download={item.fileName}
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
              className="hiddenElement"
            >
              Download Document
            </a>
            <Icon icon="material-symbols:download" />
            <span className="tooltip">Download Document</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              ShowExtendedSideBar("DocumentManagementEdit", item);
            }}
          >
            <Icon icon="akar-icons:pencil" />
            <span className="tooltip">Edit Document</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              ShowExtendedSideBar("DocumentManagementDelete", item);
            }}
          >
            <Icon icon="akar-icons:trash-can" />
            <span className="tooltip">Delete Document</span>
          </div>
        );
      },
      width: 3,
    }),
  ];

  return (
    <ForagerTable
      columns={knowledgeBaseColumns}
      items={knowledgeBases}
      subTableColumns={documentColumns}
      subTableItemsProperty={"docs"}
      onClickSubRow={HandleDocClick}
      triggerAllExpanded={triggerAllExpanded}
    />
  );
}
