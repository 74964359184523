import React, { useState, useRef } from "react";
import Menu from "./Menu/Menu";
import IQLogoSVG from "../../Generic/IQLogoSVG";

function Sidebar(props) {
  const [menuOpen, SetMenuOpen] = useState(false);
  const currentMenuContainer = useRef(null);
  const currentMenuContainerInner = useRef(null);

  function CloseMenuOnClickBackground(e) {
    if (
      e.target === currentMenuContainer.current ||
      e.target === currentMenuContainerInner.current
    ) {
      SetMenuOpen(false);
    }
  }

  function ToggleMenu() {
    SetMenuOpen(!menuOpen);
  }

  return (
    <div className="mainScreenSideContainer">
      {menuOpen ? (
        <div
          className="menuDimmerContainer"
          onClick={CloseMenuOnClickBackground}
          ref={currentMenuContainer}
        >
          <div className="menuDimmer" ref={currentMenuContainerInner}>
            <Menu
              CloseModal={ToggleMenu}
              Logout={props.Logout}
              ImpersonateUser={props.ImpersonateUser}
              impersonating={props.impersonating}
            />
          </div>
        </div>
      ) : null}
      <div className="mainScreenSidebarContainer" onClick={ToggleMenu}>
        <div className={"mainScreenIcon App-logo"}>
          <IQLogoSVG full={false} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
