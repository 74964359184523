import React from "react";
import SidebarButtons from "./SidebarButtons/SidebarButtons";
import { useSiteContext } from "../../../Context";
import { Icon } from "@iconify/react";
import ForagerButton from "../../../Generic/ForagerButton";

function Menu(props) {
  const { loggedInUser, darkMode, DarkModeSwitch, ColorSchemeSwitch } =
    useSiteContext();

  function ToggleDarkMode(e) {
    DarkModeSwitch(e.target.checked);
  }

  function ToggleColorScheme(e) {
    ColorSchemeSwitch();
  }

  return (
    <div className="menuBackground">
      <div className="menuContainer">
        <div className="menuSignOutContainer">
          <div className="darkModeToggleContainer">
            <div className="darkModeToggleText">
              <Icon icon="il:moon" />
            </div>
            <label
              className="toggleSliderContainer"
              htmlFor="darkModeToggleCheckbox"
            >
              <input
                type="checkbox"
                id="darkModeToggleCheckbox"
                checked={darkMode}
                onChange={ToggleDarkMode}
              />
              <div className="toggleSlider" />
            </label>
          </div>
          <div className="colorSchemeSwitch" onClick={ToggleColorScheme}>
            <Icon icon="ic:baseline-color-lens" />
          </div>
          <ForagerButton
            tinyText={true}
            lessRadius={true}
            noVerticalPadding={true}
            onClick={props.Logout}
          >
            Sign Out
          </ForagerButton>
        </div>
        <div className="menuHeader">
          {loggedInUser.firstName} {loggedInUser.lastName}
        </div>
        <SidebarButtons
          ImpersonateUser={props.ImpersonateUser}
          impersonating={props.impersonating}
          CloseModal={props.CloseModal}
        />
      </div>
    </div>
  );
}

export default Menu;
