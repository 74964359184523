import React, { useState } from "react";
import PDFViewerModal from "../../../Generic/PDFViewerModal";
import KnowledgeBaseTable from "./KnowledgeBaseTable";
import DocumentSearch from "./DocumentSearch";
import DocumentPreview from "./DocumentPreview";

function DocumentManagement(props) {
  const [searchText, SetSearchText] = useState("");
  const [triggerAllExpanded, SetTriggerAllExpanded] = useState(false);

  function ChangeSearchText(e) {
    SetSearchText(e.target.value);
    SetTriggerAllExpanded(true);
    let timeout = setTimeout(() => {
      SetTriggerAllExpanded(false);
      clearTimeout(timeout);
    }, 100);
  }

  return (
    <>
      <div className="documentManagement">
        <div className="DocumentBodySection">
          <div className="DocumentItemSection">
            <DocumentSearch
              searchText={searchText}
              onSearch={ChangeSearchText}
            />
            <KnowledgeBaseTable
              triggerAllExpanded={triggerAllExpanded}
              searchText={searchText}
            />
          </div>
          <DocumentPreview />
        </div>
      </div>
      <PDFViewerModal />
    </>
  );
}

export default DocumentManagement;
