import React, { useState, useEffect, useRef } from "react";
import IQMessage from "./IQMessage";

function IQLoading(props) {
  const sentences = [
    "ForagerIQ is thinking . . . ",
    "ForagerIQ is typing . . . ",
    "ForagerIQ is combing your documents . . . ",
    "ForagerIQ is looking for the best answer . . . ",
    "ForagerIQ is consulting the Jedi Council . . . ",
    "ForagerIQ is asking the Oracle of Delphi . . . ",
    "ForagerIQ is performing a Vulcan Mind Meld . . . ",
    "ForagerIQ is consulting the Elder Gods . . . ",
    "ForagerIQ is searching the Library of Alexandria . . . ",
    "ForagerIQ is opening Pandora's Box . . . ",
    "ForagerIQ is opening the Ark of the Covenant . . . ",
    "ForagerIQ is consulting the Necronomicon . . . ",
    "ForagerIQ is reading the Darkhold . . . ",
    "ForagerIQ is checking the Book of Vishanti . . . ",
  ];
  const [sentence, SetSentence] = useState("");
  const [currentText, SetCurrentText] = useState(
    sentences[Math.floor(Math.random() * sentences.length)]
  );
  const currentIndex = useRef(0);

  // Set a new sentence every time the component is rendered
  useEffect(() => {
    SetSentence(sentences[Math.floor(Math.random() * sentences.length)]);
    SetCurrentText("");
    currentIndex.current = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For each sentence, display one letter at a time
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex.current < sentence.length - 1) {
        SetCurrentText(currentText + sentence[currentIndex.current]);
        currentIndex.current = currentIndex.current + 1;
      } else {
        SetCurrentText(sentence.slice(0, sentence.length - 6));
        currentIndex.current = sentence.length - 6;
      }
    }, 150);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentText]);

  return currentText && <IQMessage message={currentText} role={"assistant"} />;
}
export default IQLoading;
