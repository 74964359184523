import { Icon } from "@iconify/react";
import PDFViewer from "../../../Generic/PDFViewer";
import { useIQContext } from "../../../IQContext";

function DocumentPreview() {
  const { filePreview } = useIQContext();

  return (
    <div className="DocumentPreviewSection">
      {filePreview !== null ? (
        <div className="DocumentPreviewWrapper">
          <PDFViewer file={filePreview} pageNumber={1} isPreview={true} />
        </div>
      ) : (
        <Icon
          icon="mdi:file-find"
          className="JobFileImagePreviewNotFoundIcon"
        />
      )}
    </div>
  );
}

export default DocumentPreview;
