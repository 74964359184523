import { useEffect } from "react";
import { useIQContext } from "../Components/IQContext";

export default function useGetKnowledgeBases() {
  const { knowledgeBases, GetKnowledgeBases } = useIQContext();

  function GetKnowledgeBasesByIds(ids) {
    return knowledgeBases.filter((kb) => ids.some((id) => id === kb.id));
  }

  useEffect(() => {
    GetKnowledgeBases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { knowledgeBases, GetKnowledgeBasesByIds };
}
