import React, { useEffect, useState } from "react";
import axios from "axios";
import PermissionsSection from "./PermissionsSection";
import * as Constants from "../../../../../../../../../Constants";
import { useSiteContext } from "../../../../../../../../../Context";
import DropDownMenu from "../../../../../../../../../Generic/DropDownMenu";

function UserPermissions(props) {
  const [permissionsSections, SetPermissionsSections] = useState([]);
  const [addPermissions, SetAddPermissions] = useState([]);
  const [removePermissions, SetRemovePermissions] = useState([]);
  const [selectedRole, SetSelectedRole] = useState(0);
  const { loggedInUser, mainUser, AddAlert, GetData } = useSiteContext();

  //eslint-disable-next-line
  const [roles, SetRoles] = useState([
    // { id: 1, name: "Admin" },
    // { id: 2, name: "Manager" },
    // { id: 3, name: "User" },
    // { id: 4, name: "Guest"}
    { id: 100, name: "Add All" },
    { id: 101, name: "Remove All" },
  ]);

  function ChangeRole(value) {
    SetSelectedRole(value);
  }

  function ProcessRoleChange() {
    let currentPermissions = [];
    let allPermissions = [];
    permissionsSections.forEach((section) => {
      section.permissions.forEach((permission) => {
        if (mainUser.permissions.some((p) => permission.id === p.id)) {
          allPermissions.push(permission.id);
          if (props.user.permissions.some((p) => permission.id === p.id)) {
            currentPermissions.push(permission.id);
          }
        } else {
          console.log(permission.name + " not found in user permissions");
        }
      });
    });
    let permissionsToAdd = allPermissions.filter(
      (p) => !currentPermissions.includes(p)
    );
    if (selectedRole === 100) {
      SetAddPermissions(permissionsToAdd);
      SetRemovePermissions([]);
    } else if (selectedRole === 101) {
      SetRemovePermissions(currentPermissions);
      SetAddPermissions([]);
    }
  }

  async function GetPermissions() {
    let newPermissions = await GetData("PermissionSection", {
      customer: loggedInUser.customer.id,
    });
    newPermissions = newPermissions.sort(
      (a, b) => a.priorityInList - b.priorityInList
    );
    SetPermissionsSections(newPermissions);
  }

  function UpdatePermissions() {
    const data = {
      addPermissions: addPermissions,
      removePermissions: removePermissions,
    };

    axios({
      method: "patch",
      url:
        Constants.API_URL +
        `User/${props.user.id}/Permissions` +
        Constants.API_KEY,
      headers: {
        "Content-Type": "application/json",
        UserId: loggedInUser.id,
        EntityType: "User",
      },
      data: data,
    })
      .then((res) => {
        AddAlert("notification", "Permissions Updated");
        props.RefreshUsers();
        props.CloseModal();
      })
      .catch((error) => {
        console.log(error);
        AddAlert("error", "Error Updating Permissions");
      });
  }

  function PermissionChange(id, name, checked) {
    let hasPermission = props.user.permissions.some(
      (permission) => permission.id === id
    );
    if (checked) {
      if (!hasPermission) {
        SetAddPermissions([...addPermissions, id]);
      } else {
        SetRemovePermissions(removePermissions.filter((p) => p !== id));
      }
    } else {
      if (hasPermission) {
        SetRemovePermissions([...removePermissions, id]);
      } else {
        SetAddPermissions(addPermissions.filter((p) => p !== id));
      }
    }
  }

  useEffect(() => {
    if (selectedRole !== 0) {
      ProcessRoleChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  useEffect(() => {
    GetPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="adminModal tableOnly">
      <div className="adminModalBodyContainer tableOnly">
        <div className="adminModalTitleBar threeSection">
          <div className="adminModalSelect role">
            <DropDownMenu
              options={roles}
              value={selectedRole}
              showBlank={true}
              onChange={ChangeRole}
            />
          </div>
          <div className="adminModalTitle">Permissions</div>
          <div></div>
        </div>
        <div className="adminModalBody permissionManagement">
          {permissionsSections.map((ps) => (
            <PermissionsSection
              permissionsSection={ps}
              key={ps.id}
              currentPermissions={props.user.permissions}
              addPermissions={addPermissions}
              removePermissions={removePermissions}
              PermissionChange={PermissionChange}
            />
          ))}
        </div>
        <div className="adminModalTitleBar">
          <button className="adminItemButton" onClick={UpdatePermissions}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserPermissions;
