// const dev = false;
const dev =
  process.env.NODE_ENV === "development" ||
  document.location.hostname.includes("dev") ||
  document.location.hostname.includes("proud-hill-06f25a10f");

if (dev) {
  console.log("In Development Mode - Using Dev API Links");
}

export const API_URL =
  process.env.API_LOCATION === "local"
    ? "http://localhost:7071/api/"
    : dev
    ? "https://pgi-api-prod.azurewebsites.net/api/"
    : "https://pgi-api-prod.azurewebsites.net/api/";
// export const API_URL = "http://localhost:7071/api/";

console.log("API_URL", API_URL, "API_LOCATION", process.env.API_LOCATION);

export const API_KEY = dev
  ? "?code=yDOpKDZ3ZGJCi3-aD_h5Tl58YBGtwM7AjKmD3ao7UM3MAzFu2tjrBw==" // Dev
  : "?code=yDOpKDZ3ZGJCi3-aD_h5Tl58YBGtwM7AjKmD3ao7UM3MAzFu2tjrBw=="; // Prod

// export const API_URL = dev
//   ? "https://forageriq-func.azurewebsites.net/api/" // Dev
//   : "https://forageriq-func.azurewebsites.net/api/"; // Prod

// export const API_KEY = dev
//   ? "?code=isrgAiCqp3mQcpZFLhXhQtEAdTmV84SXwwX41UxpPSoLAzFu2fw99Q==" // Dev
//   : "?code=isrgAiCqp3mQcpZFLhXhQtEAdTmV84SXwwX41UxpPSoLAzFu2fw99Q=="; // Prod

export const PDF_VIEWER_WORKER_URL =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js";

export const countries = [
  { id: 1, name: "United States" },
  { id: 2, name: "US Territories" },
  { id: 3, name: "Canada" },
];

export const states = [
  { id: "AL", name: "Alabama", country: 1 },
  { id: "AK", name: "Alaska", country: 1 },
  { id: "AZ", name: "Arizona", country: 1 },
  { id: "AR", name: "Arkansas", country: 1 },
  { id: "CA", name: "California", country: 1 },
  { id: "CO", name: "Colorado", country: 1 },
  { id: "CT", name: "Connecticut", country: 1 },
  { id: "DE", name: "Delaware", country: 1 },
  { id: "DC", name: "District Of Columbia", country: 1 },
  { id: "FL", name: "Florida", country: 1 },
  { id: "GA", name: "Georgia", country: 1 },
  { id: "HI", name: "Hawaii", country: 1 },
  { id: "ID", name: "Idaho", country: 1 },
  { id: "IL", name: "Illinois", country: 1 },
  { id: "IN", name: "Indiana", country: 1 },
  { id: "IA", name: "Iowa", country: 1 },
  { id: "KS", name: "Kansas", country: 1 },
  { id: "KY", name: "Kentucky", country: 1 },
  { id: "LA", name: "Louisiana", country: 1 },
  { id: "ME", name: "Maine", country: 1 },
  { id: "MD", name: "Maryland", country: 1 },
  { id: "MA", name: "Massachusetts", country: 1 },
  { id: "MI", name: "Michigan", country: 1 },
  { id: "MN", name: "Minnesota", country: 1 },
  { id: "MS", name: "Mississippi", country: 1 },
  { id: "MO", name: "Missouri", country: 1 },
  { id: "MT", name: "Montana", country: 1 },
  { id: "NE", name: "Nebraska", country: 1 },
  { id: "NV", name: "Nevada", country: 1 },
  { id: "NH", name: "New Hampshire", country: 1 },
  { id: "NJ", name: "New Jersey", country: 1 },
  { id: "NM", name: "New Mexico", country: 1 },
  { id: "NY", name: "New York", country: 1 },
  { id: "NC", name: "North Carolina", country: 1 },
  { id: "ND", name: "North Dakota", country: 1 },
  { id: "OH", name: "Ohio", country: 1 },
  { id: "OK", name: "Oklahoma", country: 1 },
  { id: "OR", name: "Oregon", country: 1 },
  { id: "PA", name: "Pennsylvania", country: 1 },
  { id: "RI", name: "Rhode Island", country: 1 },
  { id: "SC", name: "South Carolina", country: 1 },
  { id: "SD", name: "South Dakota", country: 1 },
  { id: "TN", name: "Tennessee", country: 1 },
  { id: "TX", name: "Texas", country: 1 },
  { id: "UT", name: "Utah", country: 1 },
  { id: "VT", name: "Vermont", country: 1 },
  { id: "VA", name: "Virginia", country: 1 },
  { id: "WA", name: "Washington", country: 1 },
  { id: "WV", name: "West Virginia", country: 1 },
  { id: "WI", name: "Wisconsin", country: 1 },
  { id: "WY", name: "Wyoming", country: 1 },
  { id: "AS", name: "American Samoa", country: 2 },
  { id: "GU", name: "Guam", country: 2 },
  { id: "MP", name: "Northern Mariana Islands", country: 2 },
  { id: "PR", name: "Puerto Rico", country: 2 },
  { id: "VI", name: "Virgin Islands", country: 2 },
  { id: "AB", name: "Alberta", country: 3 },
  { id: "BC", name: "British Columbia", country: 3 },
  { id: "MB", name: "Manitoba", country: 3 },
  { id: "NB", name: "New Brunswick", country: 3 },
  { id: "NL", name: "Newfoundland and Labrador", country: 3 },
  { id: "NT", name: "Northwest Territories", country: 3 },
  { id: "NS", name: "Nova Scotia", country: 3 },
  { id: "NU", name: "Nunavut", country: 3 },
  { id: "ON", name: "Ontario", country: 3 },
  { id: "PE", name: "Prince Edward Island", country: 3 },
  { id: "QC", name: "Quebec", country: 3 },
  { id: "SK", name: "Saskatchewan", country: 3 },
  { id: "YT", name: "Yukon", country: 3 },
];

export const colorOptions = [
  { name: "Standard", id: 1, colorClass: "standard" },
  { name: "Variant 1", id: 2, colorClass: "variant1" },
  { name: "Variant 2", id: 3, colorClass: "variant2" },
  { name: "Variant 3", id: 4, colorClass: "variant3" },
  { name: "Variant 4", id: 5, colorClass: "variant4" },
  { name: "Variant 5", id: 6, colorClass: "variant5" },
];

export const fieldTypeOptions = [
  { id: "Text", name: "Text" },
  { id: "Textarea", name: "Long Text" },
  { id: "Date", name: "Date" },
  { id: "Checkbox", name: "Checkbox" },
  { id: "Number", name: "Number" },
];

export const allCustomizableFields = [
  {
    id: 1,
    name: "General",
    isMergeChild: false,
    mergedName: "General",
    orderInList: 1,
    fields: [
      {
        id: -1,
        name: "User",
        orderInList: 1,
        fieldName: "user",
        defaultType: "Text",
        isCustomField: false,
      },
      {
        id: -2,
        name: "Job",
        orderInList: 2,
        fieldName: "job",
        defaultType: "Text",
        isCustomField: false,
      },
      {
        id: -5,
        name: "Task",
        orderInList: 3,
        fieldName: "task",
        defaultType: "Text",
        isCustomField: false,
      },
      {
        id: -7,
        name: "Client",
        orderInList: 4,
        fieldName: "client",
        defaultType: "Text",
        isCustomField: false,
      },
    ],
  },
  {
    id: 2,
    name: "Job",
    isMergeChild: false,
    mergedName: "Schedule Items",
    orderInList: 2,
    fields: [
      {
        id: 141,
        name: "Project Manager",
        orderInList: 1,
        fieldName: "projectManager",
        defaultType: "Text",
        isCustomField: false,
      },
      {
        id: 143,
        name: "Estimated Time",
        orderInList: 2,
        fieldName: "budgetedHours",
        defaultType: "Number",
        isCustomField: false,
      },
      {
        id: 10000,
        name: "Custom Field",
        orderInList: 12,
        fieldName: "customField",
        defaultType: "Text",
        isCustomField: false,
      },
    ],
  },
  {
    id: 3,
    name: "Task",
    isMergeChild: true,
    mergedName: "Schedule Items",
    orderInList: 3,
    fields: [
      {
        id: 142,
        name: "Project Manager",
        orderInList: 1,
        fieldName: "projectManager",
        defaultType: "Text",
        isCustomField: false,
      },
      {
        id: 144,
        name: "Estimated Time",
        orderInList: 2,
        fieldName: "budgetedHours",
        defaultType: "Number",
        isCustomField: false,
      },
      {
        id: 20000,
        name: "Custom Field",
        orderInList: 12,
        fieldName: "customField",
        defaultType: "Text",
        isCustomField: false,
      },
    ],
  },
];

export const sharedCustomizations = [
  {
    name: "Schedule Items",
    fields: [
      {
        mainId: 20,
        subIds: [39],
      },
    ],
  },
];

export const allSharedCustomizations = sharedCustomizations.map(
  (sharedGroup) => {
    return sharedGroup.fields.map((field) => {
      return field.subIds.map((subId) => {
        return { mainId: field.mainId, subId: subId };
      });
    });
  }
);

export const features = {
  Base: 1,
  IQ: 2,
  HQ: 3,
};

export const permissionSections = {
  userManagement: 2,
  customerManagement: 3,
  job: 4,
  task: 5,
  fieldCustomization: 6,
  clients: 7,
  auditLog: 8,
  timeEntry: 9,
  expenseReports: 10,
  reports: 11,
  status: 12,
  customerSettings: 13,
  errorLog: 14,
  comments: 15,
};

export const permissionTypes = {
  view: 1,
  add: 2,
  edit: 3,
  delete: 4,
  other: 5,
};
