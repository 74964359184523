import React from "react";
import DOMPurify from "dompurify";
import Markdown from "markdown-to-jsx";
import { Icon } from "@iconify/react";
import IQLogoSVG from "../../../Generic/IQLogoSVG";
import { useIQContext } from "../../../IQContext";

function IQMessage(props) {
  const { OpenInPDFViewer, SetPDFPageNumber } = useIQContext();

  function IconBuilder({ href, children }) {
    let pageNumber = 1;
    if (href.indexOf("#page=") !== -1) {
      // Store the page number (after #page=) in a variable as an int then remove it from the href
      pageNumber = parseInt(href.substr(href.indexOf("#page=") + 6));
      href = href.substr(0, href.indexOf("#page="));
    }

    return (
      <span className="IQMessageLinkWrapper">
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            HandleClick(href, pageNumber);
          }}
        >
          {children}
        </a>
        {props.role === "assistant" && (
          <Icon
            className="IQMessageLinkIcon"
            icon="material-symbols:open-in-browser"
            onClick={() => HandleClick(href, pageNumber)}
            data-tip="Open PDF in browser"
          />
        )}
      </span>
    );
  }

  function HandleClick(fileUri, pageNumber = 1) {
    if (!fileUri.includes("blob.core")) {
      window.open(fileUri, "_blank").focus();
      return;
    }
    SetPDFPageNumber(pageNumber);
    OpenInPDFViewer(fileUri);
  }

  function MessageBuilder(message, role) {
    // Capture any links that might not be in anchor tags, whether they start with http or not (e.g. www.google.com or osha.gov)
    let linkRegex =
      /((?:https?:\/\/)?(?:[\w-]+\.)*[\w-]+(?:\.[a-z]{2,6})+(?:\/[\w#!:.?+=&%@!-/]+)?)/gi;
    let markdownLinkRegex = /\[(.*?)\]\((.*?)\)/gi;
    let anchorTagRegex = /<a href(.*?)<\/a>/gi;
    if (
      message.match(linkRegex) !== null &&
      message.match(anchorTagRegex) === null &&
      message.match(markdownLinkRegex) === null
    ) {
      // Check if link is in markdown format
      if (message.match(markdownLinkRegex) === null) {
        message = message.replace(
          linkRegex,
          '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
        );
      }
    }

    // Check if link includes noopenener or noreferrer
    if (
      message.match(linkRegex) !== null &&
      message.match(/noopener noreferrer/gi) === null
    ) {
      message = message.replace(
        /<a href(.*?)>/gi,
        '<a href$1 target="_blank" rel="noopener noreferrer">'
      );
    }

    if (role === "user") {
      message = DOMPurify.sanitize(message);
    }

    const markdownOptions = {
      overrides: {
        a: {
          component: IconBuilder,
        },
      },
    };

    return <Markdown options={markdownOptions}>{message}</Markdown>;
  }

  return (
    props.message !== null &&
    props.message !== "" && (
      <>
        <div className={`messageContainer ${props.role}`}>
          {props.role === "assistant" && (
            <div className="avatarWrapper">
              <IQLogoSVG className="avatarIcon" />
            </div>
          )}
          <div className="message">
            {MessageBuilder(props.message, props.role)}
          </div>
          {props.role === "user" && (
            <div className="avatarWrapper">
              <Icon icon="mdi:account" className="avatarIcon" />
            </div>
          )}
        </div>
      </>
    )
  );
}

export default IQMessage;
