function SelectedKnowledgeBases({ selectedKnowledgeBases }) {
  return (
    <div className="knowledgeBasesSelectedContainer">
      <div
        className={
          "knowledgeBasesSelected" +
          (selectedKnowledgeBases.length === 0 ? " pleaseSelect" : "")
        }
      >
        {selectedKnowledgeBases.length === 0 ? (
          <div className="selectedKnowledgeBase pleaseSelect">
            Please select a knowledge base.
          </div>
        ) : (
          selectedKnowledgeBases
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((knowledgeBase) => (
              <div className="selectedKnowledgeBase" key={knowledgeBase.id}>
                {knowledgeBase.name}
              </div>
            ))
        )}
      </div>
    </div>
  );
}

export default SelectedKnowledgeBases;
