export default function DocumentSearch({ searchText, onSearch }) {
  return (
    <div className="searchbar">
      Search Documents:{" "}
      <input
        type="text"
        value={searchText}
        onChange={onSearch}
        placeholder="Search documents..."
      />
    </div>
  );
}
