import { useEffect } from "react";
import { useIQContext } from "../Components/IQContext";

export default function useGetDocs() {
  const { documents, GetDocuments } = useIQContext();

  useEffect(() => {
    GetDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return documents;
}
