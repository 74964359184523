import PDFViewer from "./PDFViewer";
import Modal from "./Modal";
import { useIQContext } from "../IQContext";

function PDFViewerModal({ noPadding }) {
  const { OpenInPDFViewer, PDFFileToView, PDFViewerModalOpen, PDFPageNumber } =
    useIQContext();

  function onClose() {
    OpenInPDFViewer(null);
  }

  return PDFViewerModalOpen ? (
    <Modal
      CloseModal={onClose}
      modificationClass={"pdfViewer" + (noPadding ? " nopadding" : "")}
    >
      <PDFViewer file={PDFFileToView} startPage={PDFPageNumber} />
    </Modal>
  ) : null;
}

export default PDFViewerModal;
