import React, { useEffect, useState } from "react";
import ChooseKnowledgeBase from "../ExpandedSidebars/ChooseKnowledgeBase";
import IQAdd from "../ExpandedSidebars/IQAdd";
import { useIQContext } from "../../../IQContext";
import IQHelp from "../ExpandedSidebars/IQHelp";
import { Icon } from "@iconify/react/dist/iconify.js";

function IQExpandedSideBar() {
  const [title, SetTitle] = useState("");

  const { expandedSideBar, hideSidebar, HideExpandedSidebar } = useIQContext();

  function ProcessSidebarSelection(selection) {
    switch (selection) {
      case "KnowledgeBase":
        SetTitle("Knowledge Bases");
        break;
      case "Help":
        SetTitle("Help");
        break;
      case "Settings":
        SetTitle("Settings");
        break;
      case "DocumentManagement":
      case "KnowledgeBaseManagement":
        SetTitle("Manage Documents");
        break;
      default:
        SetTitle("Knowledge Base");
    }
  }

  useEffect(() => {
    ProcessSidebarSelection(expandedSideBar);
  }, [expandedSideBar]);

  return (
    <div
      className={
        "iqExpandedSideBar" +
        (expandedSideBar !== "" && !hideSidebar ? " showing" : "")
      }
    >
      <div className="titleBar">{title}</div>
      {expandedSideBar === "KnowledgeBase" ? (
        <ChooseKnowledgeBase />
      ) : expandedSideBar === "DocumentManagement" ? (
        <IQAdd type="Document" deleteMode={false} editMode={false} mode="add" />
      ) : expandedSideBar === "DocumentManagementEdit" ? (
        <IQAdd type="Document" deleteMode={false} editMode={true} mode="edit" />
      ) : expandedSideBar === "DocumentManagementDelete" ? (
        <IQAdd
          type="Document"
          deleteMode={true}
          editMode={false}
          mode="delete"
        />
      ) : expandedSideBar === "KnowledgeBaseManagement" ? (
        <IQAdd
          type="KnowledgeBase"
          deleteMode={false}
          editMode={false}
          mode="add"
        />
      ) : expandedSideBar === "KnowledgeBaseManagementEdit" ? (
        <IQAdd
          type="KnowledgeBase"
          deleteMode={false}
          editMode={true}
          mode="edit"
        />
      ) : expandedSideBar === "KnowledgeBaseManagementDelete" ? (
        <IQAdd
          type="KnowledgeBase"
          deleteMode={true}
          editMode={false}
          mode="delete"
        />
      ) : expandedSideBar === "Help" ? (
        <IQHelp />
      ) : expandedSideBar === "Settings" ? (
        <div>Settings</div>
      ) : null}
      <div
        className="hideTab"
        onClick={() => HideExpandedSidebar(!hideSidebar)}
      >
        {expandedSideBar !== "" ? (
          <div className="displayedTab">
            <Icon icon={hideSidebar ? "ep:d-arrow-right" : "ep:d-arrow-left"} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default IQExpandedSideBar;
