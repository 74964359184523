import React from "react";
import IQ from "./IQ/IQ";
import ErrorBoundary from "../Generic/ErrorBoundary";
import IQLogoSVG from "../Generic/IQLogoSVG";
import { useSiteContext } from "../Context";
import IQContext from "../IQContext";
import Sidebar from "./Sidebar/Sidebar";

function MainScreen(props) {
  const { loggedInUser, AddData, fieldCustomizations } = useSiteContext();

  return (
    <IQContext>
      <div className="mainScreenContainer">
        <Sidebar
          Logout={props.Logout}
          ImpersonateUser={props.ImpersonateUser}
          impersonating={props.impersonating}
        />
        <div className="mainScreenContentHeader">
          <div className="mainScreenContentHeaderTitle">
            {props.impersonating ? (
              <>
                <div className="impersonationIndicator">
                  {"As Customer: " + loggedInUser.customer.name}
                </div>
                <div className="impersonationIndicator">
                  {fieldCustomizations.general.user.name +
                    ": " +
                    loggedInUser.firstName +
                    " " +
                    loggedInUser.lastName}
                </div>
              </>
            ) : null}
          </div>
          <div className="mainScreenContentHeaderClockContainer"></div>

          <div className="mainScreenContentHeaderIconContainer">
            <div className="mainLogo">
              <IQLogoSVG full={true} />
            </div>
          </div>
        </div>
        <ErrorBoundary
          loggedInUser={loggedInUser}
          AddData={AddData}
          className="mainScreenContentBody errorScreen"
        >
          <IQ />
        </ErrorBoundary>
      </div>
    </IQContext>
  );
}

export default MainScreen;
