import { useRef, useCallback } from "react";
import { useIQContext } from "../../../IQContext";
import IQExpandedSideBar from "./IQExpandedSideBar";
import DocumentManagement from "../DocumentManagement/DocumentManagement";
import IQChatBar from "./IQChatBar";
import IQChatContent from "./IQChatContent";

function IQBody() {
  const messagesEndRef = useRef(null);

  const { expandedSideBar, hideSidebar } = useIQContext();

  const contentMapping = {
    DocumentManagement: <DocumentManagement />,
    DocumentManagementEdit: <DocumentManagement />,
    DocumentManagementDelete: <DocumentManagement />,
    KnowledgeBaseManagement: <DocumentManagement />,
    KnowledgeBaseManagementEdit: <DocumentManagement />,
    KnowledgeBaseManagementDelete: <DocumentManagement />,
  };

  const ScrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesEndRef]);

  return (
    <>
      <div className={"IQBodyContainer" + (!hideSidebar ? " showSidebar" : "")}>
        <IQExpandedSideBar />
        {expandedSideBar in contentMapping ? (
          contentMapping[expandedSideBar]
        ) : (
          <>
            <IQChatContent
              ScrollToBottom={ScrollToBottom}
              ref={messagesEndRef}
            />
            <IQChatBar ScrollToBottom={ScrollToBottom} />
          </>
        )}
      </div>
    </>
  );
}

export default IQBody;
