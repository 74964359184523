import React, { useState } from "react";
import axios from "axios";
import * as Constants from "../../../../../../../../Constants";
import * as Utilities from "../../../../../../../../Utilities";
import { useSiteContext } from "../../../../../../../../Context";
import DropDownMenu from "../../../../../../../../Generic/DropDownMenu";

function AddEditUsers(props) {
  const [email, SetEmail] = useState(props.editMode ? props.user.email : "");
  const [resetPassword, SetResetPassword] = useState(
    props.user?.changePasswordNeeded ?? false
  );
  const [firstName, SetFirstName] = useState(
    props.editMode ? props.user.firstName : ""
  );
  const [middleInitial, SetMiddleInitial] = useState(
    props.editMode ? props.user.middleInitial : ""
  );
  const [lastName, SetLastName] = useState(
    props.editMode ? props.user.lastName : ""
  );
  const [title, SetTitle] = useState(props.editMode ? props.user.title : "");
  const [address1, SetAddress1] = useState(
    props.editMode ? props.user.address1 : ""
  );
  const [address2, SetAddress2] = useState(
    props.editMode ? props.user.address2 : ""
  );
  const [city, SetCity] = useState(props.editMode ? props.user.city : "");
  const [state, SetState] = useState(props.editMode ? props.user.state : "");
  const [zip, SetZip] = useState(props.editMode ? props.user.zip : "");
  const [phone, SetPhone] = useState(props.editMode ? props.user.phone : "");
  const [phoneRawDisplay, SetPhoneRawDisplay] = useState("");
  const [hourGoal, SetHourGoal] = useState(
    props.editMode ? props.user.hourGoal : ""
  );
  const { AddAlert, loggedInUser, fieldCustomizations } = useSiteContext();

  function AddUser() {
    var formData = new FormData();
    formData.append("customerId", loggedInUser.customer.id);
    formData.append("email", email);
    formData.append("firstName", firstName);
    if (middleInitial !== "") {
      formData.append("middleInitial", middleInitial);
    }
    formData.append("lastName", lastName);
    if (title !== "") {
      formData.append("title", title);
    }
    if (address1 !== "") {
      formData.append("address1", address1);
    }
    if (address2 !== "") {
      formData.append("address2", address2);
    }
    if (city !== "") {
      formData.append("city", city);
    }
    if (state !== "") {
      formData.append("state", state);
    }
    if (zip !== "") {
      formData.append("zip", zip);
    }
    if (phone !== "") {
      formData.append("phone", phone);
    }
    if (hourGoal !== "") {
      formData.append("hourGoal", hourGoal);
    }
    if (ValidateForm()) {
      axios({
        method: "post",
        url: Constants.API_URL + "library/User" + Constants.API_KEY,
        headers: {
          "Content-Type": "multipart/form-data",
          userId: loggedInUser.id,
          AddType: "User",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data !== "Failed") {
            AddAlert(
              "notification",
              fieldCustomizations.general.user.name + " added successfully"
            );
            props.RefreshUsers();
            props.CloseModal();
          } else {
            AddAlert(
              "error",
              fieldCustomizations.general.user.name + " could not be added"
            );
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.includes("already exists")) {
            console.log("Email already exists. Please use a different email.");
            AddAlert(
              "error",
              "Email already exists. Please use a different email."
            );
          }
          console.log(error);
        });
    } else {
      AddAlert("error", "Please verify all required fields");
    }
  }

  function ChangePasswordStatus(type) {
    let formData = new FormData();
    formData.append("id", props.user.id);
    formData.append("type", type);
    axios({
      method: "post",
      url: Constants.API_URL + "ChangePasswordStatus" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        userId: loggedInUser.id,
      },
      data: formData,
    })
      .then((response) => {
        if (type === "Reset") {
          if (response.data !== "Failed") {
            AddAlert("notification", "Password reset successfully");
          } else {
            AddAlert("error", "Password could not be reset");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function EditUser() {
    var formData = new FormData();
    formData.append("id", props.user.id);
    if (props.user.email !== email) {
      formData.append("email", email);
    }
    if (props.user.firstName !== firstName) {
      formData.append("firstName", firstName);
    }
    if (props.user.middleInitial !== middleInitial) {
      if (middleInitial !== "") {
        formData.append("middleInitial", middleInitial);
      }
    }
    if (props.user.lastName !== lastName) {
      formData.append("lastName", lastName);
    }
    if (props.user.title !== title) {
      if (title !== "") {
        formData.append("title", title);
      }
    }
    if (props.user.address1 !== address1) {
      formData.append("address1", address1);
    }
    if (props.user.address2 !== address2) {
      if (address2 !== "") {
        formData.append("address2", address2);
      }
    }
    if (props.user.city !== city) {
      formData.append("city", city);
    }
    if (props.user.state !== state) {
      formData.append("state", state);
    }
    if (props.user.zip !== zip) {
      formData.append("zip", zip);
    }
    if (props.user.phone !== phone) {
      formData.append("phone", phone);
    }
    if (props.user.changePasswordNeeded !== resetPassword) {
      formData.append("ChangePasswordNeeded", resetPassword);
    }

    if (ValidateForm()) {
      axios({
        method: "patch",
        url: `${Constants.API_URL}library/User/${props.user.id}${Constants.API_KEY}`,
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: loggedInUser.id,
          UpdateType: "User",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data !== "Failed") {
            AddAlert(
              "notification",
              fieldCustomizations.general.user.name + " updated successfully"
            );
            props.RefreshUsers();
            props.CloseModal();
          } else {
            AddAlert(
              "error",
              fieldCustomizations.general.user.name + " could not be updated"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      AddAlert("error", "Please verify all required fields");
    }
  }

  function ChangeEmail(e) {
    SetEmail(e.target.value);
  }

  function ChangeResetPasswordOnLogin(e) {
    SetResetPassword(e.target.checked);
  }

  function ChangeFirstName(e) {
    SetFirstName(e.target.value);
  }

  function ChangeMiddleInitial(e) {
    SetMiddleInitial(e.target.value);
  }

  function ChangeLastName(e) {
    SetLastName(e.target.value);
  }

  function ChangeTitle(e) {
    SetTitle(e.target.value);
  }

  function ChangeAddress1(e) {
    SetAddress1(e.target.value);
  }

  function ChangeAddress2(e) {
    SetAddress2(e.target.value);
  }

  function ChangeCity(e) {
    SetCity(e.target.value);
  }

  function ChangeState(value) {
    SetState(value);
  }

  function ChangeZip(e) {
    let numbersOnly = e.target.value.replace(/\D/g, "");
    const regex = /^[0-9\b]+$/;
    if (
      (numbersOnly === "" || regex.test(numbersOnly)) &&
      numbersOnly.length <= 9
    ) {
      SetZip(numbersOnly);
    }
  }

  function ChangePhone(e) {
    let numbersOnly = e.target.value.replace(/\D/g, "");
    const regex = /^[0-9\b]+$/;
    if (
      (numbersOnly === "" || regex.test(numbersOnly)) &&
      numbersOnly.length <= 10
    ) {
      SetPhone(numbersOnly);
    }
  }

  function ChangeHourGoal(e) {
    let numbersOnly = e.target.value.replace(/\D/g, "");
    const regex = /^[0-9\b]+$/;
    if (
      (numbersOnly === "" || regex.test(numbersOnly)) &&
      numbersOnly.length <= 9
    ) {
      SetHourGoal(numbersOnly);
    }
  }

  function ValidateForm() {
    if (email !== "" && firstName !== "" && lastName !== "") {
      return true;
    } else {
      return false;
    }
  }

  function SubmissionType() {
    if (props.editMode) {
      EditUser();
    } else {
      AddUser();
    }
  }

  function SubmitManualPasswordReset() {
    ChangePasswordStatus("Reset");
  }

  return (
    <div className="adminModal">
      <div className="adminModalBodyContainer tableOnly">
        <div className="adminModalTitleBar">
          <div className="adminModalTitle">
            {props.editMode
              ? "Edit " + fieldCustomizations.general.user.name
              : "Add " + fieldCustomizations.general.user.name}
          </div>
        </div>
        <div className="adminModalBody">
          <div className="addAdminItemFormContainer">
            <div className="adminItemInputLabel email">
              <span className="addAdminItemRequiredField">*</span>Email
            </div>
            <input
              className="ganttItemStatusValueEdit formWidth2"
              type="text"
              value={email}
              onChange={ChangeEmail}
            />
            <div className="adminItemInputLabel">
              <span className="addAdminItemRequiredField">*</span>First Name
            </div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={firstName}
              onChange={ChangeFirstName}
            />
            <div className="adminItemInputLabel">Middle Initial</div>
            <input
              className="ganttItemStatusValueEdit adminItemInputMiddleInitial"
              type="text"
              value={middleInitial}
              onChange={ChangeMiddleInitial}
            />
            <div className="adminItemInputLabel">
              <span className="addAdminItemRequiredField">*</span>Last Name
            </div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={lastName}
              onChange={ChangeLastName}
            />
            <div className="adminItemInputLabel">Title</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={title}
              onChange={ChangeTitle}
            />
            <div className="adminItemInputLabel">Address 1</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={address1}
              onChange={ChangeAddress1}
            />
            <div className="adminItemInputLabel">Address 2</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={address2}
              onChange={ChangeAddress2}
            />
            <div className="adminItemInputLabel">City</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={city}
              onChange={ChangeCity}
            />
            <div className="adminItemInputLabel">State</div>
            <div className="ganttItemStatusDropDownContainer">
              <DropDownMenu
                className="ganttItemStatusDropDown"
                value={state}
                onChange={ChangeState}
                options={Constants.states}
                sortBy="country"
              />
            </div>
            <div className="adminItemInputLabel">Zip</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={zip}
              onChange={ChangeZip}
            />
            <div className="adminItemInputLabel">Phone</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={
                phoneRawDisplay
                  ? phone
                  : Utilities.FormatPhone(phone.toString())
              }
              onChange={ChangePhone}
              onFocus={() => SetPhoneRawDisplay(true)}
              onBlur={() => SetPhoneRawDisplay(false)}
            />
            {loggedInUser.isHammr ? (
              <>
                <div className="adminItemInputLabel">Hour Goal</div>
                <input
                  className="ganttItemStatusValueEdit"
                  type="text"
                  value={hourGoal}
                  onChange={ChangeHourGoal}
                />
              </>
            ) : null}
            {props.user != null && props.user.id !== 0 ? (
              <div className="addAdminItemResetPasswordInfo">
                Reset Password On Next Login
                <input
                  className="addAdminItemCheckBox"
                  type="checkbox"
                  checked={resetPassword}
                  onChange={ChangeResetPasswordOnLogin}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="adminModalTitleBar">
          <button className="adminItemButton" onClick={SubmissionType}>
            {props.editMode ? "Update" : "Submit"}
          </button>
          {props.user != null && props.user.id !== 0 ? (
            <button
              className="adminItemButton"
              onClick={SubmitManualPasswordReset}
            >
              Reset Password Now
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AddEditUsers;
